<template>
  <div class="table_container">
    <!--通知框-->
    <v-snackbar
        timeout="2000"
        :value="snackbarShow"
        :color="snackbarColor"
        absolute
        top
        v-model="snackbarShow"
    >{{snackbarText}}
    </v-snackbar>
    <!--表-->
    <v-data-table
        :loading="loading"
        loading-text="加载中,请稍等..."
        :search="search"
        :headers="headers"
        :items="desserts"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        class="elevation-1"
        @page-count="pageCount = $event"
        height="600px"
        sort-by="id"
    >
      <!--      标题栏-->
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>房屋安全展示</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="搜索关键字"
              single-line
              hide-details
          ></v-text-field>
        </v-toolbar>
        <!--    筛选-->
        <div>
          <div class="d-flex">
            <span class="tagName" >安全评估等级：</span>
            <v-chip-group
                active-class="light-blue darken-4  white--text"
                column>
              <v-chip v-for="(item) in ['A级','B级','C级','D级']" @click="selectLevel(item)" class="ml-3"
                      style="font-size: 13px">
                {{ item }}
              </v-chip>
            </v-chip-group>
          </div>
        </div>
      </template>
      <template v-slot:item.exit ="{ item }">
        <v-btn
            dark
            color="#0288D1"
            small
            style="font-weight: bold"
            @click="showBt(item.riskEvaluation)"
        >查看</v-btn>
      </template>
    </v-data-table>
    <!--    页码-->
    <div class="text-center pt-4">
      <v-pagination
          v-model="page"
          :length="pageCount"
      ></v-pagination>
    </div>
<!--图表弹窗-->
    <v-dialog
        v-model="dialog"
        max-width="800px"
    >
      <v-card>
        <div class="d-flex justify-center pt-4 pb-4">
          <div id="chart" style="width: 600px;height:600px;"></div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {houseDetailMsg} from "../../network/Details/housingDisplay";

export default {
  name: "HousingDisplay",
  data () {
    return {
      loading:true,
      myChart:null,
      dialog:false,
      search:'',
      workPlaceList:'',
      jurisdictionList:[],
      allAddressList:'',
      roleList:'',
      username:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        {
          text: '编号',
          align: 'start',
          sortable: true,
          value: 'index',
        },
        { text: '楼栋名称', value: 'buildingName' },
        { text: '所属小区', value: 'estateName' },
        { text: '所属辖区', value: 'provinceName' },
        { text: '等级评估', value: 'gradeEvaluation' },
        { text: '风险评估', value: 'exit' },
      ],
      desserts: [],
      //添加用户信息
      addUserMsg:{
        username:'',
        password:'123456',
        workPlaceName:'',
        managerName:'',
        roleName:'',
        jurisdiction:[]
      },
      //编辑用户信息
      editUserMsg:{},
      tempEdit:{},
      //提示
      snackbarShow:false,
      snackbarText:'',
      snackbarColor:'#42A5F5',
    }
  },
  methods:{
    selectLevel(item){
      let level = item.charAt(0);
      houseDetailMsg({
        grade: level
      }).then(res => {
        this.desserts = res.data;
        this.loading = false;
      });
    },
    showBt(num){
      this.dialog = true;
      this.num = num;
      this.$nextTick(() => {
        this.showChart();
      });

    },
    //图表
    showChart(){
      if (this.myChart != null && this.myChart!= "" && this.myChart!= undefined) {
        this.myChart.dispose();
      }
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(document.getElementById('chart'));
      let option = {
        title: {
          text: '房屋风险评估',
          subtext: '等级评分',
          left: 'center'
        },
        series: [{
          type: 'gauge',
          startAngle: 360,
          endAngle: 0,
          min: 0,
          max: 1,
          splitNumber: 8,
          axisLine: {
            lineStyle: {
              width: 6,
              color: [
                [0.25, '#FF6E76'],
                [0.5, '#FDDD60'],
                [0.75, '#58D9F9'],
                [1, '#7CFFB2']
              ]
            }
          },
          pointer: {
            icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
            length: '12%',
            width: 20,
            offsetCenter: [0, '-60%'],
            itemStyle: {
              color: 'auto'
            }
          },
          axisTick: {
            length: 12,
            lineStyle: {
              color: 'auto',
              width: 2
            }
          },
          splitLine: {
            length: 20,
            lineStyle: {
              color: 'auto',
              width: 5
            }
          },
          axisLabel: {
            color: '#464646',
            fontSize: 20,
            distance: -60,
            formatter: function (value) {
              if (value === 0.875) {
                return '优';
              }
              else if (value === 0.625) {
                return '中';
              }
              else if (value === 0.375) {
                return '良';
              }
              else if (value === 0.125) {
                return '差';
              }
            }
          },
          title: {
            offsetCenter: [0, '-20%'],
            fontSize: 30
          },
          detail: {
            fontSize: 50,
            offsetCenter: [0, '0%'],
            valueAnimation: true,
            formatter: function (value) {
              return Math.round(value * 100) + '分';
            },
            color: 'auto'
          },
          data: [{
            value: this.num,
            name: '风险评估'
          }]
        }]
      };
      this.myChart.setOption(option);
    },
    //点击编辑
    exit(item){
      this.editUserMsg = item;
      window.sessionStorage.setItem('tempEdit',JSON.stringify(item));
      this.dialog2 = true;
    },
    //删除弹窗
    showDialog(item){
      this.dialog3 = true;
      this.tempItem = item;
    },
  },
  created() {
    houseDetailMsg().then(res => {
      this.desserts = res.data;
      this.loading = false;
    });

  },
}
</script>

<style scoped>
.table_container{
  margin-top: 2%;
  width: 94%;
  margin-left: 3%;
}
.tagName {
  margin-top: 13px;
  font-size: 13px;
  margin-left: 10px
}
</style>